// root-level scss file
// import all partials and external styles here
// this is imported in App.js so that all styles are
// available to the entire application
@import '~@trv-tds/core/dist/sass/tds.scss';
@import '~@trv-tds/core/dist/tokens/tokens.scss';
@import '_variables';
@import '_nav';
@import '_mixins';

.center-container{
  padding-top:120px;
}

.button-container{
  padding:10px 0
}

.no-margin{
  margin:0;
}

.subtext{
  font-size:.8em;
}

.c-info-dark2{
  color:$c-info-dark2;
}
.c-info-light2{
  color:$c-info-light2;
}
.info-icon{
  position:relative;
  top:4px;
  left:7px;
}
.pireg-outline__none{
  outline: none;
}
.info-icon:hover{
  color:$c-info-light2;
}
 .mobile-margin{
    padding-top :80px;
  }

  .view-container-medium{
    width:460px;
    margin:auto;
  }

  @media only screen and (max-width: 462px) {
    .view-container-medium {
      width:80%;
      @include space(padding,left,10px)   
    }
    .mobile-margin{
      @include space(padding,top,40px)
    }
    .tds-h1--small{
      font-weight: 520;
    }
    .otp-container{
      width:38px !important;
    }
  }
.tds-check__description {
  font-size: 12px;
  margin-top: 2px;
  margin-left: 2.4em;
  padding-right: 20px;
}
.underText{
  font-size: 11px;
  text-align: left;
}
.pageheader-text{
  font-size: $s-h1-small-max;
}
.pireg-fs12{
  font-size: $s-body-x-small;
}
.pireg-fs16{
  font-size: $s-root-font;
}

.align-center{
  @include textAlign(center)
}

.align-left{
  @include textAlign(left)
}

.align-right{
  @include textAlign(right)
}

  @media only screen and (max-width: 480px){
    .sm-align-left{
      @include textAlign(left)
    } 
    .sm-pl-s{
      @include space(padding,left,15px)
    }
    .sm-pr-s{
      @include space(padding,right,15px)
    }
    .sm-mt-s{
      @include space(margin,top,15px)
    }
    .sm-ml-0{
      @include space(margin,left,0px)
    }
  }

// PIN input styles
.multi-text{
  width:100%;
  height:100%;
  text-align: center;
  display:inline-block;   
}
.multi-text-cover{
  display:inline-block;
  width:62px;
  height:50px;
  margin-right:10px;
  margin-bottom:0px;
}
.multi-text-container{
  display:block;
}
// end PIN input styles
.grecaptcha-badge{
  z-index: 1;
}

@media only screen and (max-width: 420px){
  .tds-global-header__identity-block{
    margin-bottom: 1em;
  }
}

@media  only screen and  (min-width: 500px){
  .pireg-gloabalheader__align{
    display: inline;
  }
}
.medium-container{
  margin: auto;
  width:620px;
}
@media only screen and (max-width: 767px){
  .medium-container{
    width:90%;
  }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {
  .medium-container{
      width:90%;
  }
}