@import '../../styles/app.scss';
@import "~@trv-ebus/tds-icons/dist/css/functional/tds-icons";
@keyframes spinner {
    to {transform: rotate(360deg);}
}
.spinner{
    position: absolute;
    height: 50px;
    width: 50px;
    top: 40%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    background-size: 100%;
    animation: spinner .8s linear infinite;
}

  .pireg-confirmationModal__border{

    .tds-field__message--error{
      margin-bottom: -4px;
   }
  }
  .border-bottom-none {
    border-bottom:none !important;
  }

    .bulletContainer > span:last-child{
    padding-left: 10px;
  }

  .bullet { /* add the new bullet point */
    background-color: #46494d;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    display: inline-block;
  }
  .secondLineintend {
    padding-left: 2em;
    text-indent:-2em;
  }
  .pireg-textdecor__none {
    text-decoration:none;
  }
  .pireg-liststyle__none {
    list-style-type:none;
  }
  .tds-modal__dialog-header.modal__dialog-header-adjust {
    padding: 0.9375em 2em;
  }

  @media  only screen and  (max-width: 768px){
    .secondLineintend__specialcharacter{
     padding-left: 2.5em !important;
     padding-right:0px !important;
    }
  }
  .secondLineintend__specialcharacter {
    padding-left: 2em;
    text-indent: -2em;
    padding-right:10px;
  }
  
  @media  only screen and  (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
    .pipasswordrule-ipad__landscape{
     max-width:100% !important;
    }
    .secondLineintend {
      padding-left: 1em !important;
     }
     .secondLineintend__specialcharacter{
      padding-right:0px !important;
     }
  }
  .svg-align {
    margin-bottom: -3px;
  }
  @media  only screen and  (max-width: 480px){
    .pireg-modal__action{
      display: flex;
      @include space(padding,left,30px);
      @include space(padding,right,30px);
    }
    .pireg-modal__confirm{
      display: flex;
    }
  }
