@mixin textAlign($side){
    text-align:$side;
}
@mixin space($type, $direction, $pixel){
	@if $type == padding {
		@if $direction == all{
			padding: $pixel;
		} @else if $direction == top {
			padding-top:$pixel
		} @else if $direction == left {
			padding-left:$pixel
		} @else if $direction == right {
			padding-right:$pixel
		} @else {
			padding-bottom:$pixel
		}
	} @else {
		@if $direction == all{
			margin: $pixel;
		} @else if $direction == top {
			margin-top:$pixel
		} @else if $direction == left {
			margin-left:$pixel
		} @else if $direction == right {
			margin-right:$pixel
		} @else {
			margin-bottom:$pixel
		}
	}
}